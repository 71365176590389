@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

@import "~react-toastify/dist/ReactToastify.css";
@import "~react-input-range/lib/css/index.css";

.invalid-feedback {
  color: red !important;
}

.jqx-grid-column-header, .jqx-grid-statusbar-material {
  z-index: 0 !important;
}

.jqx-widget-content-material {
  z-index: 1 !important;
}
.scale-image-8{
  transform: scale(0.8)
}
.scale-image-6{
  transform: scale(0.6)
}


.jqx-grid-column-header {
  border-left: 1px solid #E0E0E0 !important;
}

.css-2613qy-menu {
  z-index: 1000000 !important;
  background-color: red;
}

.css-xn1lin {
  z-index: 1000000 !important;
  background-color: red;
}

.my-custom-react-select__menu {
  z-index: 2;
}

.my-custom-react-select__control {
  border-width: 1px !important;
  border-color: #cbd5e0 !important;
  padding-top: 0.16rem;
  padding-bottom: 0.16rem;
}

//.jqx-grid-cell-material{
//  z-index: 79 !important;
//}

.my-custom-react-select__control--is-focused {
  border-color: #746df7 !important;
  box-shadow: none !important;
}

.my-custom-react-select__indicator-separator {
  display: none;
}

.pagination {
  display: flex;
  float: right;
}

.pagination-active {
  background-color: #0c5460;
  color: white;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.react-tagsinput-tag {
  width: auto !important;
}

.tag-input-custom {
  padding: 2px !important;
}

.image-crop-center {
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 25px;
}

.rowColor {
  background-color: #e5e5e5 !important;
}

.variant-image:hover, .active-variant-image {
  border: 1px solid #4CAF50
}

.spinner {
  animation: spin infinite 5s linear;

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select2-filter {
  border: none;
}

.badge-green {
  background-color: #BEF781 !important;
}

.badge-red {
  background-color: #FFD0D0 !important;
}

.badge-blue {
  background-color: #AAB7F9 !important;
}

.scrollarea{
  min-height: 85vh !important;
}
.input-with-icon {
  div {
    .form-group {
      position: relative;

      input {
        margin-bottom: 15px;
        // border: none;
        //   box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
        //height: 50px;
        padding-left: 30px;
      }

      i {
        position: absolute;
        top: 10px;
        right: 30px;
        color: $dark-gray;
      }
    }
  }
}

.a-text-color {
  color: blue;
  cursor: pointer;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.jqx-delete-button-div {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  position: absolute;
}

.ril__captionContent{
  width: 100%;
}

.nav-item .active{
  color: #0c63e4 !important;
}
.border-tab{
  cursor: pointer;
}
.text-limit{
  font-size: 12px;
  color: green;
}

.lightbox-div div{
  z-index: 100;
}
.product-price{
  font-size: 15px !important;
}

 //.checkbox label::before {
 //  content: "";
 //  display: inline-block;
 //  position: absolute;
 //  width: 19px;
 //  height: 19px;
 //  left: 0;
 //  margin-left: -16px;
 //  border: 1px solid #7c5b5b;
 //  border-radius: 3px;
 //  background-color: #304f7e;
 //  color: white;
 //  font-weight: bolder;
 //  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
 //}

.iconscontainer {
  display: none !important;
}

.notification-dropdown {
  width: 900% !important;
}
  .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:not(.profile-dropdown) li:first-child {
    //background-color: white !important;
  }
.image-color:hover, .active-image-color {
  border: 3px solid #4CAF50;
  cursor: pointer;
}
.card-height-3{
  height: 420px;
}
.card-height-2{
  height: 280px;
}
@media only screen and (max-width: 1400px) {
  body {
    font-size: 12px;
  }
.project-list .border-tab.nav-tabs .nav-item .nav-link{
  font-size: 12px;
}
  .card-height-3{
    height: 250px;
  }
  .card-height-2{
    height: 160px;
  }
  .product-details{
    zoom: 0.7;
  }
  .ribbon {
    padding: 0 15px;
    height: 20px;
    line-height: 20px;

  }
}

.list-group li a {
  cursor: pointer;
}
.active-colum{
  border: 3px solid black;
}