.ui-sortable .container:nth-child(odd) {
    background-color: transparent !important;
    width: 100% !important;
}

.ui-sortable .container div {
    background-color: #ffffff;
    color: #030303;
    height: 75px;
}

.item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.f-w-300 {
    margin: 0;
}

.edit-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #070707;
    padding: 10px;
    border-radius: 4px;
}

.edit-button i {
    width: 35px;
    height: 35px;
    font-size: 16px;
    padding: 11px;
    margin-right: 10px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
}

.pagination li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    list-style: none;
}

.pagination a {
    display: inline-block;
    padding: 5px 10px;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 4px;
    text-decoration: none;
}

.pagination a:hover {
    background-color: #ddd;
}

.pagination-active a {
    background-color: #007bff;
    color: #fff;
}

.custom-control-label::before {
    background-color: #f2f2f2;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
    background-color: #007bff;
}